import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import {
  IFixed,
  IFluid,
  ILocalFile
} from "src/types/local-files";

import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import FieldTechnicalArticle from "../components/field-technical-article";
import { IFieldTechnicalArticle } from "../components/field-technical-article/field-technical-article";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroVideo from "../components/hero-video";
import {
  breadCrumbSchema,
  localBusinessSchema,
  organizationSchema
} from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import StayInTheLoop from "../components/stay-in-the-loop";
import TeamMembers from "../components/team-members";
import { DynamicImageSrcString } from "../templates/utils/dynamicImage";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";

export interface IProfileData {
  id: string;
  relationships: {
    profile__team_profile: IProfileFields[];
  };
}

export interface IProfileFields {
  changed: string;
  path: {
    alias: string;
  };
  hidden?: boolean;
  field_full_name: string;
  field_job_title: string;
  field_twitter_username?: string;
  field_instagram_username?: string;
  field_team_micro_snippet?: string;
  field_team_summary?: {
    processed: string;
  };
  relationships?: {
    field_mugshot?: ILocalFile<IFluid>;
    field_team_about?: IFieldTechnicalArticle[] | null;
  };
}

export interface IFieldProperties {
  __typename: string;
  field_copy?: {
    processed: string;
  };
  relationships?: {
    field_images?: Array<ILocalFile<IFluid>>;
    field_image?: ILocalFile<IFluid>;
  };
}

export interface INodeData {
  node: IProfileData;
}

interface INodePageData {
  nodeAboutUsOverview: {
    title: string;
    changed: string;
    field_meta_description: string;
    field_hero_heading: string;
    field_hero_video_url: string;
    relationships: {
      field_technical_article: IFieldTechnicalArticle[];
      field_mobile_hero_image: ILocalFile<IFixed>;
      field_hero_image: ILocalFile<IFixed>;
      field_ordered_team_members: IProfileData[];
    };
  };
}

const PageTopCustom = styled.div`
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin-bottom: 0;
          padding-bottom: 10%;
          width: 28rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 31rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 39rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 53rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            padding-bottom: 8%;
            width: 69rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 76rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            padding-bottom: 11.2rem;
          }
        }
      }
    }
  }
`;

const ArticleWrapper = styled.div`
  .paragraph-full-width-image {
    padding-top: 0;
  }
`;

const AboutUs = ({ data }: { data: INodePageData }) => {
  const teamMembers =
    data.nodeAboutUsOverview.relationships.field_ordered_team_members;

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "About us",
      path: "/about-us"
    }
  ]);

  const schemaJson = [
    localBusinessSchema,
    organizationSchema,
    breadcrumbsSchemaData
  ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          canonicalPath="/about-us"
          title={data.nodeAboutUsOverview.title}
          description={data.nodeAboutUsOverview.field_meta_description}
          updatedAt={data.nodeAboutUsOverview.changed}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="medium"
            >
              <HeroVideo
                src={data.nodeAboutUsOverview.field_hero_video_url}
                fallbackImage={DynamicImageSrcString(
                  data.nodeAboutUsOverview.relationships.field_hero_image
                )}
              />

              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  {data.nodeAboutUsOverview.field_hero_heading}
                </p>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {data.nodeAboutUsOverview.title}
          </h1>

          <ArticleWrapper>
            <FieldTechnicalArticle
              type="basic"
              paragraphs={data.nodeAboutUsOverview.relationships.field_technical_article}
            />
          </ArticleWrapper>

          <TeamMembers teamMembers={teamMembers} />

          <aside>
            <StayInTheLoop />
          </aside>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export const query = graphql`
  query TeamPageQuery {
    nodeAboutUsOverview {
      title
      created
      changed
      field_meta_description
      field_hero_heading
      field_hero_video_url
      relationships {
        field_technical_article {
          __typename
          ... on paragraph__image_full_width_ {
            field_image {
              alt
            }
            relationships {
              field_image {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(
                      quality: 80
                      maxWidth: 1920
                      srcSetBreakpoints: [375, 768, 1280, 1440, 1920]
                    ) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
                filename
              }
            }
          }
          ... on paragraph__images_side_by_side_column_width {
            field_images {
              alt
            }
            relationships {
              field_images {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(
                      quality: 80
                      maxWidth: 768
                      srcSetBreakpoints: [375, 768]
                    ) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
                filename
              }
            }
          }
          ... on paragraph__boxout_plain_text {
            field_boxout_header
            field_boxout_link {
              uri
              title
            }
            field_boxout_text {
              processed
            }
          }
        }
        field_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 1920, height: 700) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_mobile_hero_image {
          localFile {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 768, height: 512) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
        field_ordered_team_members {
          relationships {
            profile__team_profile {
              ...getProfileData
            }
          }
        }
      }
    }
  }
`;

export default AboutUs;
